import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import type { User } from '@/models/auth/User';
import { AuthApiService } from '@/services/impl/auth-api-service';
import type { LoginCredentials } from '@/models/auth/LoginCredentials';

export const useAuthStore = defineStore('authStore', () => {
    const currentUserKey = 'currentUser';

    const api = new AuthApiService();

    const loggedUser = ref<User | null>(null);
    const isLoggedIn = computed(() => loggedUser.value !== null);

    const login = async (model: LoginCredentials): Promise<void> => {
        const result = await api.login(model);

        if (!result) return Promise.reject();

        loggedUser.value = result;
    };

    const logout = async (withPost: boolean): Promise<void> => {
        localStorage.removeItem(currentUserKey);
        loggedUser.value = null;
    };

    const verifyToken = async (): Promise<void> => {
        const result = await api.verify();

        if (!result) return Promise.reject();
        loggedUser.value = result;
    };

    const forgotPassword = async (email: string) : Promise<void> => {
        const result = await api.forgotPassword(email);

        if (!result) throw new Error("Error during sending and email");
    }

    const resetPassword = async (password: string, confirmPassword: string, token: string) : Promise<void> => {
        const result = await api.resetPassword({
            password: password,
            password_confirmation: confirmPassword,
            reset_password_token: token
        });

        if (!result) throw new Error("Error during sending and email");
    }

    return {
        isLoggedIn,

        login,
        logout,
        verifyToken,
        forgotPassword,
        resetPassword
    };
});
