import type { RouteRecordRaw } from 'vue-router';

const defaultRoutes: Array<RouteRecordRaw> = [
    {
        path: '/404',
        name: 'PageNotFound',
        component: () => import('../views/404.vue'),
        meta: {
            allowAnonymous: true
        }
    }
];

export default defaultRoutes;
