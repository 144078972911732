import { BaseApiService } from '@/shared/services/base-api.service';
import type { IAuthApiService } from '../auth-api.service';
import type { AuthResponse } from '@/models/auth/AuthResponse';
import type { LoginCredentials } from '@/models/auth/LoginCredentials';
import axios from 'axios';
import type { User } from '@/models/auth/User';
import type { ResetPasswordRequest } from '@/models/auth/ResetPasswordRequest';

export class AuthApiService extends BaseApiService implements IAuthApiService {
    async login (model: LoginCredentials): Promise<User> {
        const url = `${this.BASE_URL}/auth/sign_in`;
        const response = await axios.post<AuthResponse>(url, model);

        if (response.status !== 200) throw new Error('Erro during login');

        return response.data.data;
    }

    async verify (): Promise<User> {
        const url = `${this.BASE_URL}/auth/validate_token`;
        const response = await axios.get<AuthResponse>(url);

        if (response.status !== 200) throw new Error('Token invalid');

        return response.data.data;
    }

    async forgotPassword (email: string) : Promise<boolean> {
        const url = `${this.BASE_URL}/auth/password`;
        const model = { 
            email: email,
            redirect_url: window.location.origin
        };

        const response = await axios.post(url, model);

        return response.status === 200;
    }

    async resetPassword (model: ResetPasswordRequest) : Promise<boolean> {
        const url = `${this.BASE_URL}/auth/password`;
        const result = await axios.put(url, model);
        return result.status === 200;
    }
}
